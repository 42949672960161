/*  Bio Component
    siteMetaData information from gatsby-config.js
    Appears on Article Page ./src/templates/blog-post.js
*/
// Import React and Gatsby Elements
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Import Utilities and Style Elements
import { BioContainer, BioIcon, BioDescription, BioHrefLink, } from '../elements/'

// Import Components
import SVGMartin from './svg/svgmartin' 

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/icon.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <BioContainer>
      <BioIcon>
        <SVGMartin />
      </BioIcon>
      <BioDescription>
      {author?.name && (
        <p>Written by <strong>{author.name}</strong> {author?.summary || null}
          {` `} 
Connect with me on <BioHrefLink href={`https://linkedin.com/in/${social?.linkedin || ``}`}>LinkedIn</BioHrefLink>.
        </p>
      )}        
      <p>Read my OSCP preparation notes on <BioHrefLink
              href="https://www.notion.so/martin-guzman/OSCP-Prep-837a4102b1864192aa2c9642edf4469f"        
              aria-label="Navigation"
              role="button"
              target="_blank"
            >Notion</BioHrefLink> <span role="img" aria-label="Emoji Notebook with Decorative Cover">📔</span>. 
            </p>
            <p>Check out my path progression on <BioHrefLink
          href="https://tryhackme.com/p/Martin.Guzman/"        
          aria-label="Navigation"
          role="button"
          target="_blank">TryHackMe</BioHrefLink> <span role="img" aria-label="Emoji Hacker Cat">🐱‍💻</span>.</p>
      </BioDescription>
    </BioContainer>
  )
}

export default Bio